@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    body{
        font-family: 'Lato', sans-serif;
    }
}

.emoji-404{

position: relative;
animation: mymove 2.5s infinite;
}

@keyframes mymove {
33%   {top: 0px;}
66%  {top: 20px;}
100%  {top: 0px}
}

@layer utilities{
.text-404{
        background: linear-gradient(to right, #064e3b 0%, rgba(225, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0) 61%), linear-gradient(#34d399 52%, rgb(224, 246, 255) 60%, rgb(78, 99, 132) 61%); /* you can change the colors based on your preference */
        background-clip: text; /*it defines how far the background should extend within an element, here we set it to text */
        -webkit-background-clip: text; /*for browsers compatibility */
        -webkit-text-fill-color: transparent; /* specifies the fill color of text characters. We use transparent to use the background as our text fill  */
        animation: wave 2000ms ease alternate infinite;
        transition: all 0.4s ease;
      }

      @keyframes wave {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 50vw 10px;
        }
      }
}
